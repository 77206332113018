.lateNiteBlueBus {
  background: #000;
  color: #fff !important;
  background-image: radial-gradient(
    rgba(255, 255, 255, 0.3) 25%,
    transparent 2%
  );
  background-size: 3px 3px;
  animation: starsAnimation 10s infinite linear;
  
}

.lateNiteDispatch {
  background: #fff;
  color: #000;
  background-image: radial-gradient(rgba(0, 0, 0, 0.3) 25%, transparent 2%);
  background-size: 3px 3px;
  animation: starsAnimation 10s infinite linear;
}

@keyframes starsAnimation {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10% 10%;
  }
}
